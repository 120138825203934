import {Backdrop, CircularProgress, Dialog, Divider, Stack, Typography,} from "@mui/material";
import {refetchIdentity} from "../app/slice/baseWidgetSlice";
import {useAppDispatch} from "../app/hooks";
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ReplayCircleFilledTwoToneIcon from '@mui/icons-material/ReplayCircleFilledTwoTone';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {axiosInstance} from "../app/services/axios";
import {ExtensionType} from "../util/extension.type";
import {useEffect, useState} from "react";
import Auth, {Identity} from "../app/services/auth";
import Box from "@mui/material/Box";

type BotMetaData = {
    botName:string
    botProfileUrl: string
}


const fetchPrivateExtensionBotMetaData = async (participantToken:string, urlPath:string):Promise<BotMetaData|null>=>{

    const privateBots = await axiosInstance.get(
        `/extensions/${ExtensionType.PrivateExtension}/bots?identifier=${encodeURIComponent(urlPath)}`,
        {headers: {"x-chatId": participantToken}}
    );

    const privateBot = privateBots?.data?.items?.pop()
    const bot = privateBot?.liveVersion || privateBot?.draftVersions.pop()
    if(bot) {
        return {
            botName: bot.name,
            botProfileUrl: bot.profileUrl,
        }
    }

    return null
}

type ProfileProps = {
    open:boolean,
    extensionType: ExtensionType,
    urlPath: string,
    identity:Identity,
    onClose:()=>void
}


function Profile({open, extensionType, urlPath, identity, onClose}:ProfileProps) {

    const dispatch = useAppDispatch();

    const [botMetadata, setBotMetadata] = useState<BotMetaData|null>(null)

    const [isLoading, setIsLoading] = useState(false)

      useEffect(()=>{
          if(open && extensionType !== ExtensionType.PrivateExtension){
              setIsLoading(true)
              fetchPrivateExtensionBotMetaData(identity.participantToken,urlPath)
                  .then((botMetadata)=> setBotMetadata(botMetadata))
                  .catch(()=>setBotMetadata(null))
                  .finally(()=> setIsLoading(false))
            }
          },
          [open])
  return (

      <Backdrop
          sx={{ position:"absolute", zIndex:100, borderRadius: 3 }}
          open={open}
          onClick={onClose}
      >
      <Dialog
          slotProps={{
              backdrop:{ style: { display: 'none'}}
          }}
          open={open}>
          { isLoading && (
              <Box sx={{p:2}}>
                <CircularProgress />
              </Box>
            )
          }
          {
           !isLoading && (
                  <Stack direction="column"
                         justifyContent="center"
                         alignItems="left"
                         spacing={1}
                         divider={<Divider flexItem />}
                         sx={{p:1}}
                  >
                      {
                          botMetadata && (
                              <Button
                                  onClick={()=>{
                                      Auth.updateUser({allowPrivateExt:true})
                                      window.location.reload()
                                  }}
                                  style={{ padding: 2,textTransform: "none" }}>
                                  <Stack spacing={1} direction="row" alignItems="center">
                                      <Avatar sx={{ width: 35, height: 35 }} src={botMetadata.botProfileUrl}/>
                                      <Typography style={{maxWidth:'18ch'}} variant="body2" noWrap>{`Switch to ${botMetadata.botName}`}</Typography>
                                  </Stack>
                              </Button>
                          )
                      }
                      {
                          extensionType === ExtensionType.PrivateExtension && (
                              <Button
                                  onClick={()=>{
                                      Auth.updateUser({allowPrivateExt:false})
                                      window.location.reload()
                                  }}
                                  style={{ padding: 1,textTransform: "none"}}>
                                  <Stack spacing={1} direction="row" alignItems="center">
                                      <ReplayCircleFilledTwoToneIcon color="primary"/>
                                      <Typography variant="body2" noWrap>Switch to Default Bot</Typography>
                                  </Stack>
                              </Button>
                          )
                      }
                      <Button style={{ pointerEvents:'none', padding: 1,textTransform: "none" }}>
                          <Stack spacing={1} direction="row" alignItems="center">
                              <AddchartRoundedIcon color="disabled" fontSize="medium"/>
                              <Typography variant="body2"  color="gray"  noWrap>Statistics  </Typography> ⏳
                          </Stack>
                      </Button>
                      <Button onClick={()=>{
                          if(extensionType === ExtensionType.PrivateExtension){
                              Auth.clearUser()
                              window.location.reload()
                          }else {
                              dispatch(refetchIdentity(null));
                          }
                          onClose()
                      }} style={{ padding: 1,textTransform: "none" }}>
                          <Stack spacing={1} direction="row" alignItems="center">
                              <ExitToAppTwoToneIcon color="primary" fontSize="medium"/>
                              <Typography variant="body2" noWrap>Sign Out</Typography>
                          </Stack>
                      </Button>

                  </Stack>
              )

          }

      </Dialog>
      </Backdrop>
  );
}

export default Profile;
