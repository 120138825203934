import { IconButton, Tooltip} from "@mui/material";
import LockPersonIcon from '@mui/icons-material/LockPerson';

interface SignInButtonProps {
    onClick:()=>void;
}
function SignInButton(props: SignInButtonProps) {
  return (
      <Tooltip title="Sign In">
        <IconButton
            size="small"
            style={{ borderRadius: 50, background: "rgb(38 40 46)" }}
          onClick={()=>{
              props.onClick()
          }}
        >
            <LockPersonIcon fontSize="inherit" sx={{color:'white'}} />
        </IconButton>
      </Tooltip>
  );
}

export default SignInButton;
