import Countdown from "react-countdown";

type CompletableCountDownProps = {
    onComplete?:()=>void
    until:Date|number
}

function CompletableCountDown({onComplete, until}: CompletableCountDownProps) {

    return (
    <Countdown
        date={until}
        onComplete={ onComplete}
        daysInHours={true}
        renderer={({ hours, minutes, seconds, completed }) => {

            if (hours === 0 && minutes === 0){
                return <span><i>{seconds} seconds</i></span>;
            }

            if (hours === 0){
                return <span><i>{minutes} minutes {seconds} seconds</i></span>;
            }

            return <span><i>{hours} hours {minutes} minutes {seconds} seconds</i></span>;
        }}
    />
    )

}

export default CompletableCountDown;