import {ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {axiosInstance} from "../services/axios";
import {ExtensionType} from "../../util/extension.type";
import Auth, {Identity} from "../services/auth";
import {EmitEventResponseStatus} from "../dto";
import {ChatType, processChatRequest, processIncomingMessage} from "./chatFeedSlice";

export enum ConnectionStatus{
    Not_Configured = "Not_Configured",
    Connected = "Connected",
    Disconnected = "Disconnected"
}

export type IdentityResponse = {
    identity: Identity
    quota: MessageQuota
}


export type LoadBaseWidgetDataRequest = {
  urlPath: string;
  withDefault: boolean;
  isConvoApp: boolean
  extensionType: ExtensionType;
};

export type DelegationWebRequest = {
    delegationUrl: string;
};


export type MessageQuota = {
    remainingMessages:number,
    refreshAt:Date
}

export type CreditResponse = {
    identifier: string;
    botId: string
    remainingCredits: number
    refreshAt: Date

}


export type BaseWidgetSuccessState = {
  botId: string;
  isDraft: boolean;
  versionId: string;
  botName: string;
  botAvatarURL: string;
  description: string;
  extensionType: ExtensionType;
  urlPath:string
  isReady: boolean|null;
  identity: Identity;
  isConvoApp: boolean
  connectionStatus: ConnectionStatus;
  messageQuota: MessageQuota;
} ;

export type BaseWidgetFailedState = { connectionStatus: ConnectionStatus, isReady: false,  urlPath:string, isConvoApp: boolean|null };

export type BaseWidgetState = BaseWidgetSuccessState | BaseWidgetFailedState

const initialState: BaseWidgetState = {
    connectionStatus: ConnectionStatus.Not_Configured,
    isReady: false,
    urlPath: "",
    isConvoApp: null,
};

type WidgetMetaData = {
    isConvoApp: boolean;
}


type BotMetaDataResponse = {
    isDraft: boolean;
    extensionType: ExtensionType;
    versionId:string;
    botName: string;
    botAvatarURL: string;
    botId: string;
    description: string;
    identity: Identity;
    messageQuota: MessageQuota;
};

type BaseWidgetData = {
    botMetaData: BotMetaDataResponse,
    widgetMetaData: WidgetMetaData
}


export const fetchMessageQuota = createAsyncThunk(
    "baseWidget/fetchMessageQuota",
    async (_, {getState},):Promise<MessageQuota> =>{
        const { baseWidget } = getState() as { baseWidget: BaseWidgetSuccessState };
        const user = Auth.getUser() || await Auth.setAnonymousUser()
        const creditResponse =  await axiosInstance.get<CreditResponse>(`/participants/me/credits/${baseWidget.botId}`,
            {headers: {"x-chatId": user.participantToken}}
        );

        return {
            remainingMessages: creditResponse.data.remainingCredits,
            refreshAt: creditResponse.data.refreshAt
        }
    }
)

export const refetchIdentity = createAsyncThunk(
    "baseWidget/refetchIdentity",
    async (request: DelegationWebRequest|null, { getState }): Promise<IdentityResponse|null> => {
        const { baseWidget } = getState() as { baseWidget: BaseWidgetSuccessState };

        Auth.clearUser()

        const user =  (request) ? await Auth.signInWithDelegation(request.delegationUrl, baseWidget.isConvoApp) : (Auth.getUser() || await Auth.setAnonymousUser() )

        if(!user){
            return null
        }

        const creditResponse =  await axiosInstance.get<CreditResponse>(`/participants/me/credits/${baseWidget.botId}`,
            {headers: {"x-chatId": user.participantToken}}
        );
        return {
            quota: {
                remainingMessages: creditResponse.data.remainingCredits,
                refreshAt: creditResponse.data.refreshAt
            },
            identity: user
        }

    }
)

export const loadBaseWidgetData = createAsyncThunk(
  "baseWidget/fetchData",
  async (request: LoadBaseWidgetDataRequest): Promise<BaseWidgetData|null> => {

      const user = Auth.getUser() || await Auth.setAnonymousUser()

      // If user has opted for private extensions then we first try to grab those
      if(user.allowPrivateExt) {

          const privateBots = await axiosInstance.get(
              `/extensions/${ExtensionType.PrivateExtension}/bots?identifier=${encodeURIComponent(request.urlPath)}`,
              {headers: {"x-chatId": user.participantToken}}
          );

          const privateBot = privateBots?.data?.items?.pop()
          const liveVersion = privateBot?.liveVersion
          const draftVersion = privateBot?.draftVersions.pop()
          const bot = liveVersion || draftVersion


          const creditResponse =  await axiosInstance.get<CreditResponse>(`/participants/me/credits/${bot.botId}`,
              {headers: {"x-chatId": user.participantToken}}
          );

          if(bot){
              return {
                  botMetaData: {
                      isDraft:!liveVersion,
                      extensionType: ExtensionType.PrivateExtension,
                      botName: bot.name,
                      botAvatarURL: bot.profileUrl,
                      botId: bot.botId,
                      versionId:bot.versionId,
                      description: bot.description,
                      identity: user,
                      messageQuota:{
                          remainingMessages: creditResponse.data.remainingCredits,
                          refreshAt: creditResponse.data.refreshAt
                      }
                  },
                  widgetMetaData:{
                      isConvoApp: request.isConvoApp
                  }
              };
          }

      }


      const requestURLPath = request.extensionType === ExtensionType.CommercialExtension ? new URL(request.urlPath).origin :  request.urlPath

      //If user has not opted for private extensions then we try to find a specific bot based on the channel url.
      const specifiedBots = await axiosInstance.get(`/extensions/${request.extensionType}/bots?identifier=${encodeURIComponent(requestURLPath)}`);
      const specificBot = specifiedBots?.data?.items?.pop()?.liveVersion;
      if(specificBot){

          const creditResponse =  await axiosInstance.get<CreditResponse>(`/participants/me/credits/${specificBot.botId}`,
              {headers: {"x-chatId": user.participantToken}}
          );

          return {
              botMetaData: {
                  isDraft: false,
                  extensionType: request.extensionType,
                  botName: specificBot.name,
                  botAvatarURL: specificBot.profileUrl,
                  botId: specificBot.botId,
                  versionId: specificBot.versionId,
                  description: specificBot.description,
                  identity: user,
                  messageQuota: {
                      remainingMessages: creditResponse.data.remainingCredits,
                      refreshAt: creditResponse.data.refreshAt
                  },
              },
              widgetMetaData:{
                  isConvoApp: request.isConvoApp
              }
          };
      }

      if(request.withDefault){
          //If there is no specific bot for the url we try to find a default bot.
          const defaultBots = await axiosInstance.get(`/extensions/${ExtensionType.CommercialExtension}/bots?identifier=${encodeURIComponent(`https://youtube.com`)}`);
          const defaultBot = defaultBots?.data?.items?.pop()?.liveVersion;
          if(defaultBot){

              const creditResponse =  await axiosInstance.get<CreditResponse>(`/participants/me/credits/${defaultBot.botId}`,
                  {headers: {"x-chatId": user.participantToken}}
              );
              return {
                  botMetaData: {
                      isDraft: false,
                      extensionType: ExtensionType.CommercialExtension,
                      botName: defaultBot.name,
                      botAvatarURL: defaultBot.profileUrl,
                      botId: defaultBot.botId,
                      versionId: defaultBot.versionId,
                      description: defaultBot.description,
                      identity: user,
                      messageQuota: {
                          remainingMessages: creditResponse.data.remainingCredits,
                          refreshAt: creditResponse.data.refreshAt
                      }
                  },
                  widgetMetaData:{
                      isConvoApp: request.isConvoApp
                  }
              };
          }
      }
      // If none of the above options worked we just give up :(
      return null
  }
);

export const baseWidgetSlice = createSlice({
  name: "baseWidget",
  initialState: initialState as BaseWidgetState,
  reducers: {
        updateConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
            state.connectionStatus  = action.payload
        },
    },
  extraReducers: (builder:ActionReducerMapBuilder<BaseWidgetState>) => {
    builder
        .addCase(loadBaseWidgetData.pending,  (state, action) => {
           state.urlPath = action.meta.arg.urlPath
        })
        .addCase(fetchMessageQuota.fulfilled,  (state, action) => {
            if(state.isReady){
                return {
                    ...state,
                    messageQuota: {
                        remainingMessages: action.payload.remainingMessages,
                        refreshAt: action.payload.refreshAt,
                    }
                }
            }
        })
      .addCase(
        loadBaseWidgetData.fulfilled,
        (state  , action: PayloadAction<BaseWidgetData|null>)=> {
            if(action.payload) {
                return {
                    isReady: true,
                    isDraft: action.payload.botMetaData.isDraft,
                    botId: action.payload.botMetaData.botId,
                    versionId: action.payload.botMetaData.versionId,
                    botName: action.payload.botMetaData.botName,
                    botAvatarURL: action.payload.botMetaData.botAvatarURL,
                    description: action.payload.botMetaData.description,
                    identity: action.payload.botMetaData.identity,
                    extensionType: action.payload.botMetaData.extensionType,
                    connectionStatus: state.connectionStatus,
                    urlPath:state.urlPath,
                    messageQuota: action.payload.botMetaData.messageQuota,
                    isConvoApp:action.payload.widgetMetaData.isConvoApp,
                }
            }
            return {
                isReady: false,
                connectionStatus: state.connectionStatus,
                urlPath:state.urlPath,
                isConvoApp:state.isConvoApp
            }
        }
      ).addCase(processChatRequest.fulfilled, (state, action) => {
            if(action.payload.status === EmitEventResponseStatus.SUCCESS){
                if(state.isReady){
                    return {
                        ...state,
                        messageQuota: {
                            remainingMessages: action.payload.data.remainingCredits,
                            refreshAt: action.payload.data.creditResetAt,
                        }
                    }
                }
            }
        })
        .addCase(processIncomingMessage.fulfilled, (state, action) => {
            if(action.payload.type === ChatType.REQUEST){
                if(state.isReady){
                    return {
                        ...state,
                        messageQuota: {
                            remainingMessages: state.messageQuota.remainingMessages - 1 ,
                            refreshAt:state.messageQuota.refreshAt
                        }
                    }
                }
            }
        })
      .addCase(loadBaseWidgetData.rejected, (state) => {
          return {
              isReady:false,
              connectionStatus: state.connectionStatus,
              urlPath:state.urlPath,
              isConvoApp:state.isConvoApp
          }
      })
       .addCase(refetchIdentity.pending, (state) => {
           state.isReady = null
        })
        .addCase(
            refetchIdentity.fulfilled,
            (state, action: PayloadAction<IdentityResponse|null>)=> {
                if(action.payload && state.isReady === null) {
                    return {
                        ...state,
                        isReady: true,
                        identity: action.payload.identity,
                        messageQuota: action.payload.quota
                    }
                }
                return {
                    isReady: false,
                    connectionStatus: state.connectionStatus,
                    urlPath:state.urlPath,
                    isConvoApp:state.isConvoApp
                }
            }
        )
        .addCase(refetchIdentity.rejected, (state) => {
            return {
                isReady:false,
                connectionStatus: state.connectionStatus,
                urlPath:state.urlPath,
                isConvoApp:state.isConvoApp
            }
        })

      .addDefaultCase(() => {});
  },
});


export const { updateConnectionStatus } = baseWidgetSlice.actions;

export const selectBaseWidgetData = (state: RootState) => state.baseWidget;

export default baseWidgetSlice.reducer;
