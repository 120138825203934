import { useState } from "react";
import {Button, InputAdornment, TextField, Typography} from "@mui/material"
import SendIcon from '@mui/icons-material/Send';
import Box from "@mui/material/Box";


interface ChatTextBoxPros{
    sendHandler: (message: string)=>void,
    isChatReady: boolean
}

function ChatTextBox({sendHandler, isChatReady}:ChatTextBoxPros) {

    const [content, setContent] = useState("");


    return (
        <Box sx={{display: "flex", alignItems: "center", gap:'0.5em'}}>
        <TextField
            onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                    if(content.trim()!==""){
                        ev.preventDefault();
                        const message = content
                        setContent("")
                        sendHandler(message)
                    }
                }
            }}
            InputProps={{ 
                sx: { 
                    borderRadius: 2, 
                    border:'0.1rem solid rgba(209,209,209,1)', 
                    background:'rgba(246,246,246,1)',
                    paddingRight:0.4,
                    fontSize:'0.9em',
                    ...(content!=="") && { paddingBottom:2 }

                },
                endAdornment:
                    <InputAdornment position="end">
                        {(content!=="") &&
                            <Box sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 15,
                                fontSize: '0.65rem',
                                color: 'text.secondary',
                            }}
                        >
                            {content.length}/255
                        </Box>
                        }
                </InputAdornment>

            }}
            fullWidth
            multiline
            inputProps={{
                maxLength: 255,
                style:{
                    paddingRight:'0.3em',
                    scrollbarWidth:'thin',
                    scrollbarGutter:'hidden',
                }
            }}
            maxRows={3}
            value={content} 
            onChange={event=>setContent(event.target.value)}
            placeholder = {"Type a message"}
            size="small" 
            style={{width:'inherit', minWidth:'250px', paddingRight:0}}/>

<Button
    variant="contained"
    size='small'
    disableElevation
    disabled={(content.trim() ==="") || !isChatReady}
    onClick={()=>{
        const message = content
        setContent("")
        sendHandler(message)
    }}
    style={{
        borderRadius:5,
            minHeight: 0,
            minWidth: 0,
            padding: 6
    }}>
<SendIcon fontSize="small" />
        </Button>
        </Box>
    )

}

export default ChatTextBox